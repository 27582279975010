@use "../config/" as *;
.waf-statslisting {
    @extend %p-4;
    .waf-head {
        .title {
            @extend %mb-2;
        }
    }
    .tab-container-head,
    .player {
        @extend %d-none;
    }
    .card {
        &-list {
            @extend %mt-4;
            @extend %flex;
            @extend %flex-wrap;
            @extend %gap-6;
        }
        &-item {
            @extend %w-100;
        }
        &-head {
            .title {
                @extend %mb-5;
                @extend %font-16-pb;
                @extend %secondary-800;
            }
        }
        &-body {
            border-radius: var(--half-radius) var(--half-radius) 0 0;
            @extend %pure-white-900-bg;
        }
        &-footer {
            .btn {
                &-more {
                    height: 3.6rem;
                    border-radius: 0 0 var(--half-radius) var(--half-radius);
                    @extend %flex-c-c;
                    @extend %primary-300-bg;
                    @extend %uppercase;
                }
                &-text {
                    @extend %neutral-900;
                }
            }
        }
    }
    .stats {
        &-item {
            &:not(:last-child) {
                border-bottom: .1rem solid clr(neutral-50);
            }
            &.stats-item-head {
                border-bottom: 0;
            }
        }
        &-list {
            &:not(.team-ranking) {
                .featured {
                    .stats-data-wrap {
                        display: grid;
                        grid-template-columns: 5.5rem 1fr;
                        row-gap: var(--space-1);
                    }
                    .player {
                        height: 5.5rem;
                        width: 5.5rem;
                        aspect-ratio: 1;
                        border: .1rem solid clr(primary-300);
                        @extend %circle-radius;
                        @extend %d-block;
                        &-link {
                            grid-area: 1 / 1 / 3 / 2;
                        }
                    }
                    .flag {
                        width: 3rem;
                        align-self: flex-end;
                        &-link {
                            grid-area: 1 / 2 / 2 / 3;
                        }
                    }
                    .stats-player-info {
                        grid-area: 2 / 2 / 3 / 3;
                    }
                }
            }
        }
        &-data,
        &-data-wrap,
        &-row,
        &-name {
            @extend %flex-n-c;
        }
        &-row {
            @extend %p-1-4;
        }
        &-data {
            min-height: 5.4rem;
            @extend %h-100;
            &-wrap {
                @extend %gap-2;
            }
        }
        &-name {
            @extend %gap-1;
            @extend %capitalize;
            @extend %flex-wrap;
            .name {
                @extend %font-12-pr;
                &:last-child {
                    font-weight: 700;
                }
            }
        }
        &-player-info {
            @extend %neutral-800;
        }
    }
    .points .text,
    .card-footer .btn-text {
        @extend %font-12-pb;
    }
    .pos,
    .points {
        flex-shrink: 0;
    }
    .featured {
        .stats-data {
            min-height: 6.5rem;
        }
        .stats-row {
            @extend %secondary-100-bg;
        }
        .name {
            @extend %font-14;
        }
        .points .text {
            @extend %font-16;
        }
        .pos .text {
            @extend %font-12
        }
        .flag {
            width: 4.2rem;
        }
    }
    .pos {
        width: 5rem;
        @extend %mr-2;
        @extend %gap-2;
        @extend %relative;
        .text {
            @extend %font-10-pb;
        }
        &::after {
            content: "";
            width: .1rem;
            @include position-combo("y-center", 0);
            @extend %neutral-1000-bg;
            @extend %h-50;
        }
    }
    .range {
        @extend %flex-n-c;
        @extend %font-10-pb;
        @extend %gap-1;
    }
    .neutral {
        height: .5rem;
        aspect-ratio: 1;
        @extend %font-0;
        @extend %neutral-100-bg;
        @extend %circle-radius;
    }
    .up {
        @extend %success-800;
        &:before {
            @include icon(p-arrow-up, 7);
        }
    }
    .down {
        @extend %error-800;
        &:before {
            @include icon(p-arrow-down, 7);
        }
    }
    .points {
        @extend %ml-auto;
        .text {
            @extend %ml-2;
        }
    }
    .flag {
        width: 2.5rem;
        height: auto;
        border: .04rem solid clr(pure-white-900, 3);
        @extend %half-radius;
    }
    .tabs {
        max-width: 100%;
    }
    .filter-actions,
    .more-filters {
        @extend %d-none;
    }
    .filter {
        &-wrap,
        &-tab-wrap {
            @extend %flex;
        }
        &-section,
        &-wrap {
            @extend %w-100;
        }
        &-tab-wrap {
            @extend %flex-wrap;
            @extend %gap-4;
        }
        &-section {
            position: unset;
        }
        &-wrap {
            height: 4.6rem;
        }
        &-btn {
            flex: 1;
            border-radius: var(--half-radius);
            @extend %relative;
            &:not(:first-child) {
                &::before {
                    content: "";
                    width: .1rem;
                    @include position-combo("y-center", null, 0);
                    @extend %neutral-1000-bg;
                    @extend %h-60;
                }
            }
            &.active {
                @extend %primary-300-bg;
                .filter-text {
                    font-weight: 700;
                    @extend %neutral-800;
                }
                &::before {
                    content: unset;
                }
                + .filter-btn {
                    &::before {
                        content: unset;
                    }
                }
            }
        }
    }
    .stats-item-head {
        border-radius: var(--half-radius) var(--half-radius) 0 0;
        @extend %neutral-1000-bg;
        .pos::after {
            @extend %neutral-50-bg;
        }
        .stats {
            &-row {
                @extend %py-0;
            }
            &-data {
                min-height: 3.2rem;
                .text {
                    @extend %uppercase;
                    @extend %neutral-900;
                    @extend %font-10-pb;
                }
            }
        }
    }
    &.waf-shimmer {
        .pos .text,
        .fname,
        .lname,
        .points .text,
        .up::before,
        .down::before {
            @extend %font-0;
        }
        .pos .text {
            @include shimmer(1.2rem, 1.2rem, var(--half-radius));
        }
        .featured .flag {
            @include shimmer(3rem, 2rem, var(--half-radius));
        }
        .range {
            @include shimmer(2.1rem, 1.2rem, var(--half-radius));
        }
        .flag {
            @include shimmer(2.5rem, 1.5rem, var(--half-radius));
        }
        .stats-name {
            @include shimmer(10rem, 1.5rem, var(--half-radius));
        }
        .points .text {
            @include shimmer(3rem, 2rem, var(--half-radius));
        }
        .team-ranking .featured .flag {
            @include shimmer(4.2rem, 2.8rem, var(--half-radius));
        }
        .stats-list:not(.team-ranking) .featured .player {
            @include shimmer(5.5rem, 5.5rem, 50%);
        }
    }
}
@media (min-width: $tablet-min-width) {
    .waf-statslisting {
        padding-inline: 0;
        .card {
            &-list {
                margin-top: var(--space-6);
                @include card-count(2, var(--space-6));
            }
            &-head {
                .title {
                    margin-bottom: var(--space-6);
                }
            }
        }
        .featured .name {
            font-size: 1.6rem;
        }
        .stats-list {
            &:not(.team-ranking) {
                .featured {
                    .stats-data-wrap {
                        display: flex;
                    }
                    .flag {
                        align-self: center;
                    }
                }
            }
        }
        .filter {
            &-tab-wrap {
                flex-wrap: nowrap;
            }
            &-btn {
                padding-inline: 0;
                &::after {
                    content: unset;
                }
            }
            &-section {
                //nesting to override existing css
                .filter-wrap {
                    width: 100%;
                }
            }
        }
    }
}