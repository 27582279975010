@use "../config/" as *;
.waf-statslisting {
    .tabs {
        max-width: 24rem;
        @extend %py-1;
        @extend %px-2;
        @extend %secondary-1000-bg;
    }
    .tab {
        &-container-head {
            @extend %my-6;
            .title {
                @extend %font-16-pb;
                @extend %capitalize;
                @extend %neutral-800;
            }
        }
        &-name {
            height: 3rem;
            @extend %transparent-bg;
            .btn-text {
                @extend %pure-white-900;
                @extend %font-12-pb;
                @extend %uppercase;
            }
            &.active {
                border-radius: var(--half-radius);
                @extend %primary-300-bg;
                .btn-text {
                    @extend %neutral-800;
                }
            }
        }
    }
    .waf-select-box {
        .sub-title,
        .title {
            @extend %font-14-pb;
            @extend %uppercase;
            @extend %neutral-800;
        }
        .list-item {
            padding-inline: 0;
            &-text {
                line-height: 2rem;
                @extend %font-14-pm;
                @extend %neutral-600;
            }
            &.active {
                .list-item-action .list-item-text {
                    font-weight: 700;
                }
            }
        }
    }
    .filter {
        &-tab-wrap {
            @extend %relative;
        }
        &-actions {
            @include position(-1.2rem, -1.6rem, null, null, absolute);
        }
        &-section {
            .btn-more {
                height: 3.8rem;
                width: 9.3rem;
            }
        }
        &-text {
            @extend %uppercase;
            @extend %font-12-pm;
            @extend %neutral-600;
        }
    }
}
@media (min-width: $tablet-min-width) {
    .waf-statslisting {
        .filter {
            &-actions {
                top: -6.8rem;
                right: 0;
            }
            &-tab-wrap {
                gap: var(--space-4);
                @include flex-config(flex, null, null, center);
            }
            &-section {
                background: transparent;
                .btn-more {
                    flex-direction: row-reverse;
                    width: 13rem;
                    height: 4.6rem;
                    background: clr(pure-white-900);
                    &::before {
                        transform: rotate(90deg);
                        color: clr(neutral-600);
                        @include icon(p-arrow-right, 10);
                    }
                    .btn-text {
                        color: clr(neutral-600);
                    }
                }
                .btn-text {
                    font-size: 0;
                    &::before {
                        content: "MORE";
                        font-size: 1.2rem;
                    }
                }
                .filter-wrap {
                    width: calc(100% - 13rem);
                    gap: 0;
                }
                .more-filters {
                    overflow: visible;
                    padding: 0;
                }
                &.active {
                    .more-filters {
                        height: 0;
                        padding: 0;
                    }
                    .btn-more {
                        background: clr(primary-300);
                        &::before {
                            color: clr(neutral-800);
                        }
                    }
                    .btn-text::before {
                        content: "MORE";
                        font-weight: 700;
                        color: clr(neutral-800);
                    }
                    .select-box-wrap {
                        max-height: 44rem;
                        overflow-y: auto;
                        padding-block: var(--space-2);
                    }
                }
                .select-box-wrap {
                    display: block;
                    max-height: 0;
                    overflow: hidden;
                    transition: max-height .3s ease-in, padding .3s ease-in;
                    background: clr(pure-white-900);
                    max-width: 36rem;
                    right: 0;
                    left: unset;
                    box-shadow: 0 .4rem 1rem 0 clr(neutral-900, 5);
                    padding-inline: var(--space-3);
                }
            }
            &-wrap {
                height: 4.6rem;
                background: clr(pure-white-900);
                border-radius: var(--half-radius) 0 0 var(--half-radius);
            }
            &-btn {
                flex: 1;
                position: relative;
                padding-inline: var(--space-1);
                &.active {
                    background: clr(primary-300);
                    border-radius: var(--half-radius);
                    .filter-text {
                        color: clr(neutral-800);
                        font-weight: 700;
                    }
                    &::after {
                        display: none;
                    }
                }
                &::after {
                    content: "";
                    background: clr(neutral-1000);
                    width: .1rem;
                    height: 3rem;
                    z-index: var(--z-default);
                    @include position-combo(y-center, -0.1rem);
                }
            }
        }
        .tabs {
            padding: var(--space-2);
            max-width: 16rem;
        }
        .waf-select-box {
            height: auto;
            .selected-title::after {
                display: none;
            }
            .list-item {
                padding-block: var(--space-3);
            }
        }
        .select-list .list-item-text {
            color: clr(neutral-900);
            font-size: 1.2rem;
        }
    }
}